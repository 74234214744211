import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="thaumaturgist"></a><h3>THAUMATURGIST</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG196_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d4.</p>
    <a id="thaumaturgist-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a thaumaturgist, a
character must fulfill all the following criteria.</p>
    <p><b>Feats</b>: Spell Focus (conjuration).</p>
    <p><b>Spells</b>: Able to cast lesser planar ally.</p>
    <h6>Class Skills</h6>
    <p className="initial">The thaumaturgist's class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a>
(Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
(religion/the planes)
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense
Motive</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#speak-language">Speak Language</a> (none), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>
(Int).</p>
    <p><b>Skill Points at Each Level</b>: 2 + Int modifier.</p>
    <a id="table-the-thaumaturgist"></a><p><b>Table: The Thaumaturgist</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "16%"
          }}>Special</th>
          <th style={{
            "width": "44%"
          }}>Spells per Day</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Improved ally</td>
          <td>+1 level of existing spellcasting class</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>Augment Summoning</td>
          <td>+1 level of existing spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>Extended summoning</td>
          <td>+1 level of existing spellcasting class</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Contingent conjuration</td>
          <td>+1 level of existing spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Planar cohort</td>
          <td>+1 level of existing spellcasting class</td>
        </tr>
      </tbody>
    </table>
    <a id="thaumaturgist-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are features of the
thaumaturgist prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Thaumaturgists gain no
proficiency with any weapon or armor.</p>
    <p><b>Spells per Day</b>: When a new thaumaturgist level is
gained, the character gains new spells per day as if he had also gained
a level in whatever spellcasting class he belonged to before he added
the prestige class. He does not, however, gain any other benefit a
character of that class would have gained. This essentially means that
he adds the level of thaumaturgist to the level of whatever other
spellcasting class the character has, then determines spells per day
and caster level accordingly.</p>
    <p>If a character had more than one spellcasting class before he
became a thaumaturgist, he must decide to which class he adds each
level of thaumaturgist for the purpose of determining spells per day.</p>
    <a id="thaumaturgist-improved-ally"></a><p><b>Improved Ally</b>: When a thaumaturgist casts a planar ally
spell (including the lesser and greater versions), he makes a Diplomacy
check to convince the creature to aid him for a reduced payment. If the
thaumaturgist&#8217;s Diplomacy check adjusts the creature&#8217;s attitude to
helpful the creature will work for 50% of the standard fee, as long as
the task is one that is not against its nature. </p>
    <p>The thaumaturgist&#8217;s improved ally class feature only works
when the planar ally shares at least one aspect of alignment with the
thaumaturgist.</p>
    <p>A thaumaturgist can have only one such ally at a time, but he
may bargain for tasks from other planar allies normally.</p>
    <a id="thaumaturgist-augment-summoning"></a><p><b>Augment Summoning</b>: At 2nd level, a thaumaturgist gains
the Augment Summoning feat.</p>
    <a id="thaumaturgist-extended-summoning"></a><p><b>Extended Summoning</b>: At 3rd level and higher, all spells
from the summoning subschool that the thaumaturgist casts have their
durations doubled, as if the Extend Spell feat had been applied to
them. The levels of the summoning spells don&#8217;t change, however. This
ability stacks with the effect of the Extend Spell feat, which does
change the spell&#8217;s level.</p>
    <a id="thaumaturgist-contingent-conjuration"></a><p><b>Contingent Conjuration</b>: A 4th-level thaumaturgist can
prepare a summoning or calling spell ahead of time to be triggered by
some other event. This functions as described for the contingency
spell, including having the thaumaturgist cast the summoning or calling
spell beforehand. The spell is cast instantly when the trigger event
occurs. </p>
    <p>The conditions needed to bring the spell into effect must be
clear, although they can be general. If complicated or convoluted
condition as are prescribed, the contingent conjuration may fail when
triggered. The conjuration spell occurs based solely on the stated
conditions, regardless of whether the thaumaturgist wants it to,
although most conjurations can be dismissed normally. A thaumaturgist
can have only one contingent conjuration active at a time.</p>
    <a id="thaumaturgist-planar-cohort"></a><p><b>Planar Cohort</b>: A 5th-level thaumaturgist can use any of
the planar ally spells to call a creature to act as his cohort. The
called creature serves loyally and well as long as the thaumaturgist
continues to advance a cause important to the creature. </p>
    <p>To call a planar cohort, the thaumaturgist must cast the
relevant spell, paying the XP costs normally. It takes an offering of
1,000 gp x the HD of the creature to convince it to serve as a planar
cohort, and the improved ally class feature can&#8217;t be used to reduce or
eliminate this cost. The planar cohort can&#8217;t have more Hit Dice than
the thaumaturgist has, and must have an ECL no higher than the
thaumaturgist&#8217;s character level &#8211;2.</p>
    <p>A thaumaturgist can have only one planar cohort at a time, but
he can continue to make agreements with other called creatures
normally. A planar cohort replaces a thaumaturgist&#8217;s existing cohort,
if he has one by virtue of the Leadership feat.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      